/*
    default que usamos pra deixar a galeria
    sem estética externa, ideal para tema light

    gui santos, 19/06/24.
    yep, escondendo gambiarra de lib lixo
 */
var PKPhotoSwipeUI = new Object();

PKPhotoSwipeUI.OPTIONS = {
    closeEl: false,
    counterEl: false,
    bgOpacity: 0.8,
    captionEl: false,
    fullscreenEl: false,
    zoomEl: false,
    shareEl: false,
    galleryPIDs: false,
    history: false,
    showHideOpacity: false,
    maxSpreadZoom: 1,
    pinchToClose: true,
    tapToClose: true,
    tapToToggleControls: true
}

PKPhotoSwipeUI.optionsWithIndex = function(index) {
    return Object.assign(
        {},
        PKPhotoSwipeUI.OPTIONS,
        { index: index }
    )
}

PKPhotoSwipeUI.convert = function(jQueryItems) {
    return jQueryItems.map(function(index, element) {
        return {
            src: $(this).data("uri"),
            w: $(this).data("width"),
            h: $(this).data("height")
        };
    });
}

export default PKPhotoSwipeUI;